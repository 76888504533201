import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
 
@Injectable({
   providedIn: 'root'
})
 
export class MetaService { 
  constructor(@Inject(DOCUMENT) private dom, private apiService: ApiService, private metaTitle: Title, private metaTags: Meta, private translateService: TranslateService) { }

  setTitle(title: string, appName: boolean) {
    if (appName) {
      let lang = this.translateService.getBrowserLang();
      if (lang.startsWith('fr')) lang = 'fr_FR';
      if (lang.startsWith('en')) lang = 'en_GB';
      if (lang.startsWith('de')) lang = 'de_DE';
      if (!lang.startsWith('fr') && !lang.startsWith('en')) lang = 'fr_FR';
      this.apiService.getConfig({lang: lang}).subscribe(
        data => {
          let config = data.data[0];
          this.metaTitle.setTitle(title + config.apptitle);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.metaTitle.setTitle(title);
    }
  }

  createCanonicalURL() {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', this.dom.URL);
  }

  updateTags(options: {description?: string, descriptionFromApi?: boolean, articlePublishedTime?: string, articleModifiedTime?: string, ogLang?: boolean, ogUrl?: boolean, ogTitle?: string, ogTitleFromApi?: boolean, ogDescription?: string, ogDescriptionFromApi?: boolean, ogType?: string, ogImage?: string, ogImageWidth?: string, ogImageHeight?: string}) {
    if (options.descriptionFromApi || options.ogDescriptionFromApi || options.ogTitleFromApi) {
      let lang = this.translateService.getBrowserLang();
      if (lang.startsWith('fr')) lang = 'fr_FR';
      if (lang.startsWith('en')) lang = 'en_GB';
      if (lang.startsWith('de')) lang = 'de_DE';
      if (!lang.startsWith('fr') && !lang.startsWith('en')) lang = 'fr_FR';
      this.apiService.getConfig({lang: lang}).subscribe(
        data => {
          let config = data.data[0];
          if (options.descriptionFromApi) {
            this.metaTags.updateTag({ name: 'description', content: options.description + config.description});
          } else {
            let div = document.createElement("div");
            div.innerHTML = options.description;
            let text = div.textContent || div.innerText || "";
            this.metaTags.updateTag({ name: 'description', content: text});
          }
          if (options.ogTitleFromApi) {
            this.metaTags.updateTag({ property: 'og:title', content: options.ogTitle + config.apptitle});
          } else {
            this.metaTags.updateTag({ property: 'og:title', content: options.ogTitle});
          }
          if (options.ogDescriptionFromApi) {
            this.metaTags.updateTag({ property: 'og:description', content: options.ogDescription + config.description});
          } else {
            let div = document.createElement("div");
            div.innerHTML = options.ogDescription;
            let text = div.textContent || div.innerText || "";
            this.metaTags.updateTag({ property: 'og:description', content: text});
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      if (options.description) {
        this.metaTags.updateTag({ name: 'description', content: options.description});
      }
      if (options.ogTitle) {
        this.metaTags.updateTag({ property: 'og:title', content: options.ogTitle});
      }
      if (options.ogDescription) {
        this.metaTags.updateTag({ property: 'og:description', content: options.ogDescription});
      }
    }
    if (options.articlePublishedTime) {
      this.metaTags.updateTag({ property: 'article:published_time', content: options.articlePublishedTime});
    }
    if (options.articleModifiedTime) {
      this.metaTags.updateTag({ property: 'article:modified_time', content: options.articleModifiedTime});
    }
    if (options.ogLang) {
      let lang = this.translateService.getBrowserLang();
      this.metaTags.updateTag({ property: 'og:locale', content: lang});  
    }
    if (options.ogUrl) {
      this.metaTags.updateTag({ property: 'og:url', content: this.dom.URL});
    }
    if (options.ogType) {
      this.metaTags.updateTag({ property: 'og:type', content: options.ogType});
    }
    if (options.ogImage) {
      this.metaTags.updateTag({ property: 'og:image', content: options.ogImage});
    }
    if (options.ogImageWidth) {
      this.metaTags.updateTag({ property: 'og:image:width', content: options.ogImageWidth});
    }
    if (options.ogImageHeight) {
      this.metaTags.updateTag({ property: 'og:image:height', content: options.ogImageHeight});  
    }

  }

} 