// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseApiUrl: 'https://app.db.alsacefanday.com/api/',
  apiAuth: 'hopalsace584254485',
  apiPass: '3wWkk8zB4W7HB45m',
  mapboxPK: 'pk.eyJ1IjoiZmFuYWxzYWNlIiwiYSI6ImNsMjkybzc1bTAwNnAza280aTNqaDZ3b2cifQ.7yY2DHFpwxxQs-rTATyjrg',
  oneSignalID: '7c18641d-9edd-4b66-952d-6c0d1b02fc76',
  oneSignalSafariID: '',
  googleProjectNumber: '',
  deeplinkSplit: '',
  baseUrl: 'https://app.alsacefanday.com/',
  baseShareUrl: 'https://app.alsacefanday.com/publication/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
