import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  showInfoButton: boolean = false;
  showLiveButton: boolean = false;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    setTimeout(() => { 
      this.getMenuItems();
    }, 2000);
  }

  getMenuItems() {
    this.translateService.get('button_link_info', {value: 'world'}).subscribe((url: string) => {
      if (url !== 'button_link_info') this.showInfoButton = true;
    });
    this.translateService.get('button_link_live', {value: 'world'}).subscribe((url: string) => {
      if (url !== 'button_link_live') {
        this.showLiveButton = true;
        setTimeout(() => {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const openLive = urlParams.get('live');
          if (openLive) {
            document.getElementById("open-live").click();
          }
        }, 500);
      }
    });
  }

}
